import { authGuard } from '../../core/guards/auth.guard';
import {
  ENavigationUnitComponentSelection,
  ENavigationRoute,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';

export const ACCOUNT_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.REGISTER,
    loadComponent: () =>
      import('./pages/register/register.component').then(
        (x) => x.RegisterComponent
      ),
  },
  {
    path: ENavigationRoute.LOGIN,
    loadComponent: () =>
      import('./pages/login/login.component').then((x) => x.LoginComponent),
  },
  {
    path: ENavigationRoute.PASSWORD_REMIND,
    loadComponent: () =>
      import('./pages/password-remind/password-remind.component').then(
        (x) => x.PasswordRemindComponent
      ),
  },
  {
    path: `${ENavigationRoute.PASSWORD_CHANGE}/:key`,
    loadComponent: () =>
      import('./pages/password-change/password-change.component').then(
        (x) => x.PasswordChangeComponent
      ),
  },
  {
    path: `${ENavigationRoute.ACCOUNT_ACTIVATE}/:id`,
    loadComponent: () =>
      import('./pages/account-activate/account-activate.component').then(
        (x) => x.AccountActivateComponent
      ),
  },
  {
    path: ENavigationRoute.ACCOUNT,
    loadComponent: () =>
      import('./pages/account/account-container.component').then(
        (x) => x.AccountContainerComponent
      ),
    canActivate: [authGuard],
    data: {
      label: 'SHARED.GENERAL.ACCOUNT',
      iconName: 'person_outline',
      componentSelection: [ENavigationUnitComponentSelection.TOPBAR],
      visibleForUsers: [EUserRole.ROLE_USER],
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ENavigationRoute.CONTACT_DETAILS,
      },
      {
        path: ENavigationRoute.CONTACT_DETAILS,
        loadComponent: () =>
          import(
            './pages/account/profile/contact-details/contact-details.component'
          ).then((x) => x.ContactDetailsComponent),
        data: {
          label: 'ACCOUNT.MENU.CONTACT_DETAILS',
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
      {
        path: ENavigationRoute.COMPANY_DETAILS,
        loadComponent: () =>
          import(
            './pages/account/profile/company-details/company-details.component'
          ).then((x) => x.CompanyDetailsComponent),
        data: {
          label: 'ACCOUNT.MENU.COMPANY_DETAILS',
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
      {
        path: ENavigationRoute.ACCOUNT_SETTINGS,
        loadComponent: () =>
          import('./pages/account/settings/account-settings.component').then(
            (x) => x.AccountSettingsComponent
          ),
        data: {
          label: 'ACCOUNT.MENU.ACCOUNT_SETTINGS',
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
      {
        path: ENavigationRoute.NOTIFICATIONS,
        loadComponent: () =>
          import('./pages/notifications/notifications.component').then(
            (x) => x.NotificationsComponent
          ),
        data: {
          label: 'ACCOUNT.MENU.NOTIFICATIONS',
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
      {
        path: ENavigationRoute.FIBARO,
        loadComponent: () =>
          import('./pages/fibaro/fibaro.component').then(
            (x) => x.FibaroComponent
          ),
        data: {
          label: 'ACCOUNT.MENU.FIBARO',
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
      {
        path: `${ENavigationRoute.FIBARO}/:state`,
        loadComponent: () =>
          import('./pages/fibaro/fibaro.component').then(
            (x) => x.FibaroComponent
          ),
        data: {
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
    ],
  },
];
