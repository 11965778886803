import { authGuard } from '../../core/guards/auth.guard';
import {
  ENavigationRoute,
  ENavigationUnitComponentSelection,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';
import { ApartmentBreadcrumbResolver } from './services/api/apartment-api-breadcrumb.service';

export const PROPERTY_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.PROPERTIES,
    canActivate: [authGuard],
    data: {
      label: 'PROPERTY.HEADING',
      iconName: 'shrl_key_outline',
      componentSelection: [ENavigationUnitComponentSelection.TOPBAR],
      visibleForUsers: [EUserRole.ROLE_USER],
      breadcrumbCode: 'PROPERTY.HEADING',
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/properties-list-container/properties-list-container.component'
          ).then((x) => x.PropertiesListContainerComponent),
        data: {
          label: 'PROPERTY.HEADING_LIST',
          visibleForUsers: [EUserRole.ROLE_USER],
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
        },
      },
      {
        path: ENavigationRoute.NEW,
        loadComponent: () =>
          import('./pages/create-property/create-property.component').then(
            (x) => x.CreatePropertyComponent
          ),
        data: {
          label: 'PROPERTY.CREATE.TITLE',
          componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
      {
        path: `${ENavigationRoute.EDIT}/:id`,
        loadComponent: () =>
          import('./pages/edit-property/edit-property.component').then(
            (x) => x.EditPropertyComponent
          ),
        data: {
          visibleForUsers: [EUserRole.ROLE_USER],
        },
      },
      {
        path: ':id',
        loadComponent: () =>
          import(
            './components/property-container/property-container.component'
          ).then((x) => x.PropertyContainerComponent),
        data: {
          breadcrumbCode: 'Apartment',
        },
        resolve: { breadcrumbLabel: ApartmentBreadcrumbResolver },
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/room-list/room-list.component').then(
                (x) => x.RoomListComponent
              ),
            data: {
              visibleForUsers: [EUserRole.ROLE_USER],
            },
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./pages/room-details/room-details.component').then(
                (x) => x.RoomDetailsComponent
              ),
            data: {
              visibleForUsers: [EUserRole.ROLE_USER],
              breadcrumbCode: 'Room',
            },
            children: [
              {
                path: `${ENavigationRoute.RESERVATION}/:id`,
                loadComponent: () =>
                  import(
                    './pages/room-reservation/room-reservation.component'
                  ).then((x) => x.RoomReservationComponent),
                data: {
                  visibleForUsers: [EUserRole.ROLE_USER],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
